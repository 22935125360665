<template>
  <div>
    <RibbonList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RibbonList from "@/view/pages/ribbon/components/RibbonList";

export default {
  name: "RibbonListings",
  components: {
    RibbonList
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "RibbonListings" }]);
  },
};
</script>