<template>
  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
    <div class="card card-custom gutter-b card-stretch" :class="{'overlay overlay-block': isBlocking}">
      <!--begin::Body-->
      <div class="card-body">
        <div class="d-flex flex-column justify-content-between h-100">

        
        <!--begin::User-->
        <div class="d-flex align-items-start mb-7 flex-fill">
          <!--begin::Pic-->
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
              <div class="symbol symbol-45 p-2" v-if="true">
                <span class="symbol-label" :style="`background: ${color}`"></span>
              </div>
              <span v-if="false" class="label label-xl label-pill label-inline text-white" :style="`background: ${color}`">{{ title.tr }}</span>
            </div>
            <!--end::Pic-->
            <!--begin::Title-->
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bold font-size-h6 mb-0 provide-title">{{ title.tr }}</span>
              <span class="text-muted font-weight-bold font-size-md mb-0 provide-muted-title">{{ title.en }}</span>
            </div>
            <!--end::Title-->
          </div>
          <!--end::Title-->
        </div>
        <!--end::User-->
        <div class="d-flex align-items-center">
          <button @click="openModal" type="button" class="btn flex-fill btn-sm btn-light font-weight-bolder text-uppercase py-4">Düzenle</button>
          <b-button @click="removeModal" type="button" v-b-tooltip.hover title="Sil" class="btn btn-icon btn-lg ml-2 btn-light font-weight-bolder text-uppercase py-4">
            <i class="flaticon2-trash icon-md"></i>
          </b-button>
        </div>
        </div>
      </div>
      <!--end::Body-->
      <div class="overlay-layer bg-dark-o-10" v-if="isBlocking">
        <div class="spinner spinner-primary"></div>
      </div>
    </div>

    <b-modal :id="`modal-edit-${id}`" :ref="`modal-edit-${id}`" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleModalCancel" @hide="handleModalCancel" @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ modal.activeStep }} ADIM/{{ modal.steps.length }}</label>
            <div class="d-flex align-items-center">
              <h5 class="ml-2 mb-0">
                {{ modal.steps[modal.activeStep - 1].title }}
              </h5>
            </div>
          </div>
        </div>
      </template>

      <div class="max-h-300px">
        <div v-if="modal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1 d-flex align-items-center"><Flag class="pr-2" code="TR" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/> Etiket</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.title.tr">
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1 d-flex align-items-center"><Flag class="pr-2" code="GB-UKM" size="S" :hasDropShadow="true" :hasBorder="true" :hasBorderRadius="true"/> Etiket</label>
            <input type="text" class="form-control form-control-lg" v-model="modal.title.en">
          </div>
        </div>
        <div v-if="modal.activeStep === 2">
          <div class="form-group w-50" style="position: relative;">
            <label class="text-body font-weight-bold pl-1">Renk seçin</label>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35 cursor-pointer" @click="isColorPickerVisible = !isColorPickerVisible">
                <span class="symbol-label rounded-right-0" :style="`background: ${modal.color};`"></span>
              </div>
              <input type="text" class="form-control rounded-left-0 text-uppercase" :placeholder="`${modal.color}`" v-model="modal.color">
            </div>
            <chrome-picker :value="modal.color" @input="updateColorValue" class="mt-4" v-if="isColorPickerVisible"/>
          </div>
        </div>
      </div>
      
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
            v-if="modal.activeStep == 1"
          >İptal et</button>
           <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="modal.activeStep = modal.activeStep - 1" 
            v-if="modal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.color.length !== 7"
            @click="ok" 
            v-if="modal.activeStep == modal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.title.tr.length === 0 || modal.title.en.length === 0"
            @click="modal.activeStep = modal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { Chrome } from 'vue-color';

export default {
  props: {
    id: Number, 
    color: String,
    title: Object
  },
  components: {
    'chrome-picker': Chrome
  },
  data() {
    return {
      isColorPickerVisible: false,
      modal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Etiketin adı',
          },
          {
            number: 2,
            title: 'Etiket rengi',
          }
        ],
        id: null,
        title: { tr: '', en: '' },
        color: ''
      },
      isBlocking: false
    }
  },
  methods: {
    updateColorValue(val) {
      this.modal.color = val.hex;
    },
    openModal() {
      this.modal.id = this.id;
      this.modal.title = Object.assign({}, this.title);
      this.modal.color = this.color;
      this.$bvModal.show(`modal-edit-${this.id}`);
    },
    handleModalCancel() {
      this.modal.activeStep = 1;
      this.modal.id = null;
      this.modal.title = { tr: '', en: '' };
      this.modal.color = '';
      this.isColorPickerVisible = false;
    },
    handleModalOk() {
      const postData = {
        id: this.id,
        title: JSON.stringify(this.modal.title),
        color: this.modal.color
      }
      ApiService.post('ribbon/set.req.php', postData)
      .then(() => {
        this.$emit('edited', 'success', 'Başarılı! Değişiklikler kaydedildi.');
      })
      .catch(({ response }) => {
        this.$emit('edited', 'danger', response.data.errorMessage);
      })
    },

    removeModal() {
      const self = this;
      const body = this.$createElement(
        'span',
        { class: ['font-size-lg'] },
        `${this.title.tr} etiketini silmek istediğinize emin misiniz?`
      );
      this.$bvModal.msgBoxConfirm([body], {
        title: 'Silmek Üzeresiniz',
        size: 'md',
        buttonSize: 'lg',
        okVariant: 'dark font-weight-bolder py-4',
        okTitle: 'Evet, Sil!',
        cancelVariant: 'btn btn-hover-transparent-dark font-weight-bolder py-4',
        cancelTitle: 'Vazgeç',
        footerClass: 'd-flex flex-row align-items-center justify-content-between flex-fill p-2',
        hideHeaderClose: false,
        centered: true,
        noFade: true
      })
      .then(value => {
        if (value) { self.remove(); }
      })
      .catch(({ error }) => {
        alert(error);
      })
    },

    remove() {
      const self = this;
      this.isBlocking = true;
      const postData = {
        id: this.id
      }
      ApiService.post('ribbon/remove.req.php', postData)
      .then(() => {
        setTimeout(() => {
          this.$emit('edited', 'success', 'Başarılı! Etiket sistemden silindi.');  
        }, 1000);
      })
      .catch(({ response }) => {
        setTimeout(() => {
          self.isBlocking = false;  
          const body = this.$createElement(
            'span',
            { class: ['font-size-lg'] },
            response.data.errorMessage
          );
          self.$bvModal.msgBoxOk([body], {
            title: response.data.error,
            size: 'md',
            buttonSize: 'lg',
            okVariant: 'dark font-weight-bolder py-4',
            okTitle: 'Tamam',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
            noFade: true
          })
        }, 1000);
      })
    }

  }
}
</script>

<style scoped>
.vc-chrome {
  position: absolute;
  border-radius: 0.85rem !important;
  width: 100% !important;
}
</style>
<style>
.vc-chrome-color-wrap {
  display: none !important;
}
.vc-chrome-alpha-wrap {
  display: none !important;
}
.vc-chrome-body {
  border-radius: 0.85rem !important;
}
.vc-chrome-saturation-wrap {
  border-radius: 0.85rem 0.85rem 0 0 !important;
}
</style>