<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <div class="d-flex flex-row align-items-center">
        <h2 class="font-weight-bold my-2 mr-5">
          Etiketler
        </h2>
        <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" style="width: 1px; height:22px;"></div>
        <div class="d-flex align-items-center">
          <span class="text-dark-50 font-weight-bold" id="kt_subheader_total">{{ items.length }} Adet</span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
      <router-link
        :to="{ name: 'create-ribbon'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="svg-icon">
            <inline-svg src="media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="d-none d-md-inline">Etiket Ekle</span>
        </a>
      </router-link>
      </div>
    </div>
    <div class="row">
      <RibbonItem 
        v-for="item in items" 
        :key="item.id" 
        :id="item.id" 
        :title="item.title" 
        :color="item.color"
        @edited="ribbonEdited"
      />
    </div>
    <Notification 
      :state="notification.state" 
      :message="notification.message"
      v-if="notification.status"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import RibbonItem from "@/view/pages/ribbon/components/RibbonItem";
import Notification from "@/view/content/notification/Notification";
export default {
  components: {
    RibbonItem,
    Notification
  },
  data() {
    return {
      items: [],
      notification: {
        status: false,
        state: '',
        message: ''
      },
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get('ribbon/listings.req.php')
      .then(({ data }) => {
        this.items = data.ribbons;
      })
      .catch(({ response }) => {
        this.items = [];
        alert(response.data.errorMessage);
      })
    },
    notify(state, message) {
      this.notification.state = state;
      this.notification.message = message;
      this.notification.status = true;
      
      this.notification.timeout = setTimeout(() => {
        this.notification.status = false;
      }, 4000);
    },
    ribbonEdited(status, message) {
      this.notify(status, message);
      this.get();
    }
  }
}
</script>

<style>

</style>